import CZ from 'country-flag-icons/react/3x2/CZ';
import DE from 'country-flag-icons/react/3x2/DE';
import GB from 'country-flag-icons/react/3x2/GB';
import { changeLocale, IntlContextConsumer } from "gatsby-plugin-intl";
import styled from "../styling/styled";

const languageName = {
  en: "English",
  de: "Deutsch",
  cs: "Česky",
};

const Selector = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 0 10px;
  position: relative;

  & > .selectables {
    display: none;
    padding: 8px 16px;
    border-radius: 3px;
    position: absolute;
    top: -10px;
    right: 0px;
    background: #fff;
    z-index: 10000;
    box-shadow: 0 3px 25px rgb(0 0 0 / 20%);
  }

  &:hover,
  &:active {
    & > .selectables {
      display: block;
    }
  }
`;

const Flag = styled.div`
  display: block;
  width: 30px;
  height: 20px;
`;

const LanguageSwitch = () => {
  return (
    <span>
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) =>
        <Selector>
          <Flag>
            {currentLocale === 'cs' && (
              <CZ />
            )}
            {currentLocale === 'en' && (
              <GB />
            )}
            {currentLocale === 'de' && (
              <DE />
            )}
          </Flag>
          <div className='selectables'>
            {languages.map(language => (
              <a
                style={{
                  color: currentLocale === language ? `#aaa` : `#5f5f5f`,
                  textDecoration:
                    currentLocale === language ? `none` : `underline`,
                  marginLeft: 4,
                  marginRight: 4,
                  cursor: currentLocale === language ? `default` : `pointer`
                }}
                key={language}
                onClick={() => changeLocale(language)}
              >
                {languageName[language]}
              </a>
            ))}
          </div>
        </Selector>
        
        }
      </IntlContextConsumer>
    </span>
  );
};

export default LanguageSwitch;
