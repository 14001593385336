import { FormattedMessage } from "gatsby-plugin-intl";
import React from "react";
import Container from "../../components/Container";
import IconRounded from "../../components/IconRounded";
import {
  business,
  circleSmall,
  costs,
  loader,
  value,
  waveVerticalSmall
} from "../../components/Images";
import Row from "../../components/Row";
import styled from "../../styling/styled";
import { theme } from "../../styling/theme";

const AdvantagesContent = styled.div`
  padding-top: 60px;
  padding-bottom: 180px;
  background-size: 17px 48px, 17px 17px, 30px 30px;
  background-image: url(${waveVerticalSmall}), url(${circleSmall}),
    url(${loader});
  background-repeat: no-repeat;
  background-position: left calc(${theme.container.background} - 57px) top 23%,
    left calc(${theme.container.background} + 254px) bottom 20%,
    right calc(${theme.container.background} + 42px) bottom 29%;
  ${[theme.media.mobileMenuVisible]} {
    padding-bottom: 60px;
  }
`;

const Advantage = styled.div`
  max-width: 257px;
  flex-basis: 257px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`;

const RowAdvantages = styled(Row)`
  ${[theme.media.maxM]} {
    flex-direction: column;
  }
`;

const Col6 = styled.div`
  flex: 1;
  ${[theme.media.maxM]} {
    margin-bottom: 20px;
  }
`;

class Advantages extends React.Component {
  render() {
    return (
      <AdvantagesContent id="advantages">
        <Container>
          <RowAdvantages spacing={10}>
            <Col6>
              <Advantage>
                <IconRounded src={business} alt="Offer new advanced products" />
                <h4>
                  <FormattedMessage id="partials.homepage.advantages.title1" />
                </h4>
                <p>
                  <FormattedMessage id="partials.homepage.advantages.text1" />
                </p>
              </Advantage>
            </Col6>
            <Col6>
              <Advantage>
                <IconRounded src={costs} alt="Reduce maintenance costs" />
                <h4>
                  <FormattedMessage id="partials.homepage.advantages.title2" />
                </h4>
                <p>
                  <FormattedMessage id="partials.homepage.advantages.text2" />
                </p>
              </Advantage>
            </Col6>
            <Col6>
              <Advantage>
                <IconRounded src={value} alt="Bulid new service business" />
                <h4>
                  <FormattedMessage id="partials.homepage.advantages.title3" />
                </h4>
                <p>
                  <FormattedMessage id="partials.homepage.advantages.text3" />
                </p>
              </Advantage>
            </Col6>
          </RowAdvantages>
        </Container>
      </AdvantagesContent>
    );
  }
}

export default Advantages;
