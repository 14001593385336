import { css } from "@emotion/core";
import React from "react";
import { theme } from "../styling/theme";

interface Props {
  open: boolean;
  className?: string;
}

const MenuStyles = css`
  display: block;
  width: 30px;
  height: 28px;
  margin: 0;
  padding: 0;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  margin-left: 25px;
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: ${theme.colors.grayDark};
    opacity: 1;
    right: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }

  span:nth-of-type(1) {
    top: 0px;
  }

  span:nth-of-type(2) {
    top: ${(30 / 5) * 2}px;
  }

  span:nth-of-type(3) {
    top: ${(30 / 5) * 4}px;
  }
`;

const MenuStylesOpen = css`
  span:nth-of-type(1) {
    top: ${(30 / 5) * 2}px;
    transform: rotate(135deg);
  }

  span:nth-of-type(2) {
    opacity: 0;
    right: -${30 * 2}px;
  }

  span:nth-of-type(3) {
    top: ${(30 / 5) * 2}px;
    transform: rotate(-135deg);
  }
`;

export const Menu = (props: Props) => {
  return (
    <>
      <div css={[MenuStyles, props.open && MenuStylesOpen, props.className]}>
        <span />
        <span />
        <span />
      </div>
    </>
  );
};
