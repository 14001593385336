import styled from "../styling/styled";
import { theme } from "../styling/theme";

const ContainerSmall = styled.div`
  width: 100%;
  max-width: ${theme.container.small};
  margin: 0 auto;
  padding-left: 30px;
  padding-right: 30px;
  ${[theme.media.mobileMenuVisible]} {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export default ContainerSmall;
