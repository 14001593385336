import { injectIntl } from "gatsby-plugin-intl";
import React from "react";
import Helmet from "react-helmet";
import FooterBackground from "../components/FooterBackground";
import Layout from "../components/Layout";
import Advantages from "../partials/homepage/Advantages";
import Contact from "../partials/homepage/Contact";
import Explanation from "../partials/homepage/Explanation";
import Jumbotron from "../partials/homepage/Jumbotron";
import Steps from "../partials/homepage/Steps";
import UseCases from "../partials/homepage/UseCases";

if (typeof window !== "undefined") {
  /* tslint:disable */
  require("smooth-scroll")('a[href*="#"]', {
    speed: "1000",
    easing: "easeInOutQuad",
    offset: "80",
    updateURL: false
  });
  /* tslint:enable */
}

class IndexPage extends React.Component {
  render() {
    const intl = this.props.intl;
    return (
      <>
        <Helmet
          title={intl.formatMessage({ id: "pages.index.title1" })}
          meta={[
            {
              name: "description",
              content: intl.formatMessage({ id: "pages.index.description" })
            },
            {
              property: "og:title",
              content: intl.formatMessage({ id: "pages.index.title1" })
            },
            {
              property: "og:description",
              content: intl.formatMessage({ id: "pages.index.description" })
            }
          ]}
        >
          <html lang={intl.locale} />
        </Helmet>
        <Layout>
          <Jumbotron />
          <Advantages />
          <Explanation />
          <UseCases />
          <Steps />
          <Contact />
          <FooterBackground />
        </Layout>
      </>
    );
  }
}

export default injectIntl(IndexPage);
