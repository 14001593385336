import { FormattedMessage } from "gatsby-plugin-intl";
import React from "react";
import Button from "../../components/Button";
import Container from "../../components/Container";
import IconRounded from "../../components/IconRounded";
import {
  circleSmall,
  dataProcessing,
  energy,
  evCharging,
  facility,
  pool,
  water,
  waveVerticalSmall
} from "../../components/Images";
import Row from "../../components/Row";
import styled from "../../styling/styled";
import { theme } from "../../styling/theme";

const UseCasesContent = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  background-size: 17px 17px, 28px 28px, 17px 47px;
  background-image: url(${circleSmall}), url(${circleSmall}),
    url(${waveVerticalSmall});
  background-repeat: no-repeat;
  background-position: left calc(${theme.container.background} + 150px) top 40px,
    right calc(${theme.container.background} - 104px) bottom 120px,
    left calc(${theme.container.background} - 77px) bottom;
  ${[theme.media.mobileMenuVisible]} {
    padding-top: 30px;
    padding-bottom: 60px;
  }

  h2 {
    margin-bottom: 90px;
    ${[theme.media.mobileMenuVisible]} {
      margin-bottom: 1.25em;
    }
  }

  h3 {
    margin-top: 65px;
    margin-bottom: 65px;
    ${[theme.media.mobileMenuVisible]} {
      margin-top: 1.32em;
      margin-bottom: 1.32em;
    }
  }
`;

const RowUseCases = styled(Row)`
  ${[theme.media.maxS]} {
    display: block;
  }
`;

const Col6 = styled.div`
  max-width: calc(100% / 6);
  flex-basis: calc(100% / 6);
  padding-left: 6px;
  padding-right: 6px;
  ${[theme.media.maxMD]} {
    max-width: calc(100% / 3);
    flex-basis: calc(100% / 3);
    margin-bottom: 20px;
  }
  ${[theme.media.maxS]} {
    max-width: 100%;
    margin-bottom: 0px;
  }
`;

const UseCase = styled.div`
  max-width: 257px;
  flex-basis: 257px;
  text-align: center;
  ${[theme.media.maxS]} {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    align-items: center;
    p {
      margin-left: 15px;
      flex: 1;
      text-align: left;
    }
  }
`;

class UseCases extends React.Component {
  render() {
    return (
      <UseCasesContent>
        <Container>
          <h2>
            <FormattedMessage id="partials.homepage.usecases.title1" />
          </h2>
          <RowUseCases spacing={6}>
            <Col6>
              <UseCase>
                <IconRounded src={pool} />
                <p>
                  <FormattedMessage id="partials.homepage.usecases.text1" />
                </p>
              </UseCase>
            </Col6>
            <Col6>
              <UseCase>
                <IconRounded
                  src={evCharging}
                />
                <p>
                  <FormattedMessage id="partials.homepage.usecases.text2" />
                </p>
              </UseCase>
            </Col6>
            <Col6>
              <UseCase>
                <IconRounded src={water} />
                <p>
                  <FormattedMessage id="partials.homepage.usecases.text3" />
                </p>
              </UseCase>
            </Col6>
            <Col6>
              <UseCase>
                <IconRounded
                  src={facility}
                />
                <p>
                  <FormattedMessage id="partials.homepage.usecases.text4" />
                </p>
              </UseCase>
            </Col6>
            <Col6>
              <UseCase>
                <IconRounded src={dataProcessing} />
                <p>
                  <FormattedMessage id="partials.homepage.usecases.text5" />
                </p>
              </UseCase>
            </Col6>
            <Col6>
              <UseCase>
                <IconRounded src={energy} />
                <p>
                  <FormattedMessage id="partials.homepage.usecases.text6" />
                </p>
              </UseCase>
            </Col6>
          </RowUseCases>
          <h3>
            <FormattedMessage id="partials.homepage.usecases.title2" />
          </h3>
          <Button link="/contact/">
            <FormattedMessage id="partials.homepage.usecases.link1" />
          </Button>
        </Container>
      </UseCasesContent>
    );
  }
}

export default UseCases;
