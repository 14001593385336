import {
  FormattedHTMLMessage,
  FormattedMessage,
  injectIntl
} from "gatsby-plugin-intl";
import React from "react";
import Typed from "react-typed";
import Row from "../components/Row";
import styled from "../styling/styled";
import { theme } from "../styling/theme";
import {
  careWhite,
  dataProcessingWhite,
  evChargingWhite,
  facilityWhite,
  hexagon,
  homepageIllustration,
  poolWhite,
  waterWhite
} from "./Images";

const TypingBoxContent = styled(Typed)`
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px 19px;
  border-radius: 7px;
  background-color: ${theme.colors.red};
  color: ${theme.colors.white};
  font-size: 45px;
  ${[theme.media.maxMD]} {
    font-size: 30px;
  }

  ${[theme.media.maxM]} {
    font-size: 20px;
    padding: 5px 10px;
  }

  .typed-cursor {
    color: #aaa;
  }
`;

const TypingBoxWrapper = styled.div`
  h1 {
    margin-bottom: 1.1em;
  }
`;

const Subclaim = styled.span`
  display: block;
  margin-top: 0.5em;
`;

const RowTypingBox = styled(Row)`
  position: relative;
  align-items: center;
  ${[theme.media.maxM]} {
    display: block;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  width: 23%;
  height: 27.5%;
  background-image: url(${hexagon});
  background-size: 100% 100%;
  top: 20.5%;
  left: 36%;
  filter: drop-shadow(0 15px 20px rgba(22, 22, 38, 0.2));
  opacity: 0;
  transform: translate3d(0, 0, 0) scale(1);
  @media screen and (min-width: 1031px) and (max-width: 1060px) {
    left: 37%;
  }
  &.active {
    opacity: 1;
    animation-name: iconPulse;
    animation-duration: 0.7s;
    animation-delay: 0.1s;
  }
`;

const Icon = styled.img`
  width: 50%;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  transition-delay: 0.1s;
  &.active {
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
  }
`;

const IllustrationImage = styled.img`
  display: block;
  margin-left: auto;
`;

const Illustration = styled.div`
  z-index: -1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  ${[theme.media.maxMD]} {
    right: -40px;
    width: 50%;
    z-index: -1;
  }
  ${[theme.media.maxS]} {
    top: 10px;
    right: -40px;
    width: 50%;
    z-index: -1;
  }
`;

const Paragraph = styled.p`
  font-size: 1.2em;
`;

class TypingBox extends React.Component {
  state = {
    currentText: 0
  };

  mounted = false;

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  checkTyping(val) {
    if (!this.mounted) {
      return false;
    }

    this.setState({
      currentText: val
    });
  }

  render() {
    const intl = this.props.intl;
    return (
      <RowTypingBox spacing={15}>
        <div>
          <TypingBoxWrapper>
            <h1>
              <FormattedMessage id="components.typingbox.title1" />
              <br />
              <TypingBoxContent
                strings={[
                  intl.formatMessage({ id: "components.typingbox.string1" }),
                  intl.formatMessage({ id: "components.typingbox.string2" }),
                  intl.formatMessage({ id: "components.typingbox.string3" }),
                  intl.formatMessage({ id: "components.typingbox.string4" }),
                  intl.formatMessage({ id: "components.typingbox.string5" }),
                  intl.formatMessage({ id: "components.typingbox.string6" })
                ]}
                typeSpeed={120}
                backSpeed={30}
                backDelay={1500}
                preStringTyped={val => {
                  this.checkTyping(val);
                }}
                loop
              ></TypingBoxContent>
              <br />
              <FormattedMessage id="components.typingbox.title2" />
              <Subclaim>
                <FormattedHTMLMessage id="components.typingbox.title3" />
              </Subclaim>
            </h1>
            <Paragraph>
              <FormattedHTMLMessage id="components.typingbox.title4" />
            </Paragraph>
          </TypingBoxWrapper>
        </div>
        <div>
          <Illustration>
            <IllustrationImage
              src={homepageIllustration}
              alt="Homepage illustration"
            />

            <IconWrapper
              className={this.state.currentText === 0 ? "active" : ""}
            >
              <Icon
                className={this.state.currentText === 0 ? "active" : ""}
                src={dataProcessingWhite}
              />
            </IconWrapper>
            <IconWrapper
              className={this.state.currentText === 1 ? "active" : ""}
            >
              <Icon
                className={this.state.currentText === 1 ? "active" : ""}
                src={evChargingWhite}
              />
            </IconWrapper>
            <IconWrapper
              className={this.state.currentText === 2 ? "active" : ""}
            >
              <Icon
                className={this.state.currentText === 2 ? "active" : ""}
                src={careWhite}
              />
            </IconWrapper>
            <IconWrapper
              className={this.state.currentText === 3 ? "active" : ""}
            >
              <Icon
                className={this.state.currentText === 3 ? "active" : ""}
                src={poolWhite}
              />
            </IconWrapper>
            <IconWrapper
              className={this.state.currentText === 4 ? "active" : ""}
            >
              <Icon
                className={this.state.currentText === 4 ? "active" : ""}
                src={waterWhite}
              />{" "}
            </IconWrapper>
            <IconWrapper
              className={this.state.currentText === 5 ? "active" : ""}
            >
              <Icon
                className={this.state.currentText === 5 ? "active" : ""}
                src={facilityWhite}
              />
            </IconWrapper>
          </Illustration>
        </div>
      </RowTypingBox>
    );
  }
}

export default injectIntl(TypingBox);
