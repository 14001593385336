// tslint:disable
export const logo = require("../assets/images/header/logo.svg");
export const ixmanager = require("../assets/images/logo/logo-ixmanager.svg");
export const ixfield = require("../assets/images/logo/logo-ixfield.svg");
export const ixsolve = require("../assets/images/logo/logo-ixsolve.svg");
export const poolmatix = require("../assets/images/logo/logo-poolmatix.svg");
export const elediogo = require("../assets/images/logo/logo-eledio-go.svg");
export const levelmatix = require("../assets/images/logo/logo-levelmatix.svg");
export const value = require("../assets/images/icons/value.svg");
export const costs = require("../assets/images/icons/costs.svg");
export const careWhite = require("../assets/images/icons/care_white.svg");
export const garden = require("../assets/images/icons/uc_garden.svg");
export const gardenWhite = require("../assets/images/icons/uc_white_garden.svg");
export const pool = require("../assets/images/icons/uc_pool.svg");
export const poolWhite = require("../assets/images/icons/uc_white_pool.svg");
export const facility = require("../assets/images/icons/uc_facility.svg");
export const facilityWhite = require("../assets/images/icons/uc_white_facility.svg");
export const dataProcessing = require("../assets/images/icons/uc_data.svg");
export const dataProcessingWhite = require("../assets/images/icons/uc_white_data.svg");
export const water = require("../assets/images/icons/uc_water.svg");
export const waterWhite = require("../assets/images/icons/uc_white_water.svg");
export const energy = require("../assets/images/icons/uc_energy.svg");
export const energyWhite = require("../assets/images/icons/uc_white_energy.svg");
export const evCharging = require("../assets/images/icons/ev_charging.svg");
export const evChargingWhite = require("../assets/images/icons/ev_charging_white.svg");
export const business = require("../assets/images/icons/business.svg");
export const what_1 = require("../assets/images/homepage/what_1.jpg");
export const what_2 = require("../assets/images/homepage/what_2.jpg");
export const what_3 = require("../assets/images/homepage/what_3.jpg");
export const what_4 = require("../assets/images/homepage/what_4.jpg");
export const hexagon = require("../assets/images/icons/hexagon.svg");
export const reference = require("../assets/images/homepage/reference.svg");
export const arrowLeft = require("../assets/images/homepage/arrow_left.svg");
export const step_1 = require("../assets/images/homepage/step_1.png");
export const step_2 = require("../assets/images/homepage/step_2.png");
export const step_3 = require("../assets/images/homepage/step_3.png");
export const homepageIllustration = require("../assets/images/homepage/homepage_illustration.png");
export const circleSmall = require("../assets/images/background_particles/circle_small.svg");
export const circleLarge = require("../assets/images/background_particles/circle_large.svg");
export const waveHorizontalLarge = require("../assets/images/background_particles/wave_horizontal_large.svg");
export const waveHorizontalSmall = require("../assets/images/background_particles/wave_horizontal_small.svg");
export const waveVerticalSmall = require("../assets/images/background_particles/wave_vertical_small.svg");
export const waveVerticalLarge = require("../assets/images/background_particles/wave_vertical_large.svg");
export const target = require("../assets/images/background_particles/target.svg");
export const loader = require("../assets/images/background_particles/loader.svg");
export const aboutUsIllustration = require("../assets/images/about_us/illustration.png");
export const CTAIllustration = require("../assets/images/other/cta_illustration.png");
export const softwareIllustration = require("../assets/images/software/software_illustration.svg");
export const software1 = require("../assets/images/software/software_1.png");
export const software2 = require("../assets/images/software/software_2.png");
export const software3 = require("../assets/images/software/software_3.jpg");
export const software4 = require("../assets/images/software/software_4.png");
export const software5 = require("../assets/images/software/software_5.jpg");
export const software6 = require("../assets/images/software/software_6.png");
export const software1_2x = require("../assets/images/software/software_1_2x.png");
export const software2_2x = require("../assets/images/software/software_2_2x.png");
export const software3_2x = require("../assets/images/software/software_3_2x.png");
export const software4_2x = require("../assets/images/software/software_4_2x.png");
export const software5_2x = require("../assets/images/software/software_5_2x.png");
export const software6_2x = require("../assets/images/software/software_6_2x.png");
export const hardwareIllustration = require("../assets/images/hardware/hardware_illustration.svg");
export const hardware1 = require("../assets/images/hardware/hardware_1.png");
export const hardware2 = require("../assets/images/hardware/hardware_2.png");
export const hardware3 = require("../assets/images/hardware/hardware_3.png");
export const hardware4 = require("../assets/images/hardware/hardware_4.png");
export const hardware5 = require("../assets/images/hardware/hardware_5.png");
export const hardware1_2x = require("../assets/images/hardware/hardware_1_2x.png");
export const hardware2_2x = require("../assets/images/hardware/hardware_2_2x.png");
export const hardware3_2x = require("../assets/images/hardware/hardware_3_2x.png");
export const hardware4_2x = require("../assets/images/hardware/hardware_4_2x.png");
export const hardware5_2x = require("../assets/images/hardware/hardware_5_2x.png");
export const contactIllustration = require("../assets/images/contact/contact_illustration.svg");
export const whyIllustration = require("../assets/images/why_eledio/why_illustration.jpg");
export const blueIllustration = require("../assets/images/blue/blue_illustration.png");
export const blueIllustration_2x = require("../assets/images/blue/blue_illustration@2x.png");
export const appstore = require("../assets/images/blue/app_store.svg");
export const googleplay = require("../assets/images/blue/google_play.svg");
export const app1 = require("../assets/images/blue/app1.png");
export const app1_2x = require("../assets/images/blue/app1@2x.png");
export const app2 = require("../assets/images/blue/app2.png");
export const app2_2x = require("../assets/images/blue/app2@2x.png");
export const app3 = require("../assets/images/blue/app3.png");
export const app3_2x = require("../assets/images/blue/app3@2x.png");
export const qrcode = require("../assets/images/blue/qrcode.svg");
export const wikiIllustration = require("../assets/images/wiki/wiki_illustration.png");
export const logoEu = require("../assets/images/logo/logo-eu.png");
export const logoTheCountryForTheFuture = require("../assets/images/logo/logo-the-country-for-the-future.png");
export const logoMKEn = require("../assets/images/logo/logo-mk_en.png");
export const logoMKCs = require("../assets/images/logo/logo-mk_cs.png");
export const logoNGEuEn = require("../assets/images/logo/logo-ngeu_en.png");
export const logoNGEuCs = require("../assets/images/logo/logo-ngeu_cs.png");
export const logoNGEuDe = require("../assets/images/logo/logo-ngeu_de.png");
export const logoNPO = require("../assets/images/logo/logo-npo.png");
export const logoMPOCs = require("../assets/images/logo/logo-mpo_cs.png")
export const poweredByEledioGo = require("../assets/images/powered-by/eledio-go.png");
export const poweredByPoolmatix = require("../assets/images/powered-by/poolmatix.png");
export const poweredByLiftmatix = require("../assets/images/powered-by/liftmatix.png");
// tslint:enable
