import { css } from "@emotion/core";
import { FormattedMessage, Link } from "gatsby-plugin-intl";
import React from "react";
import styled from "../styling/styled";
import { theme } from "../styling/theme";
import Button from "./Button";
import Container from "./Container";
import { logo } from "./Images";
import LanguageSwitch from "./LanguageSwitch";
import { Menu } from "./Menu";


interface State {
  menuShown: boolean;
  className?: string;
  scrolled: boolean;
}

const Navbar = styled.div`
  position: fixed;
  z-index: 9999;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  height: 70px;
  background-color: ${theme.colors.white};
  box-shadow: none;
  transition: box-shadow 0.2s ease-in-out;
  ${[theme.media.mobileMenuVisible]} {
    padding-top: 10px;
    padding-bottom: 10px;
    height: 60px;
  }
`;

const NavbarScrolled = css`
  box-shadow: 0 3px 25px rgba(0, 0, 0, 0.1);
`;

const NavbarContent = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled(Link)`
  max-width: 119px;
  ${[theme.media.mobileMenuVisible]} {
    margin-right: auto;
  }

  ${[theme.media.maxS]} {
    max-width: 100px;
  }

  img {
    display: block;
    width: 100%;
    margin-top: 10px;
  }
`;

const MobileMenuStyles = css`
  margin-left: auto;
  ${[theme.media.mobileMenuVisible]} {
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${theme.colors.white};
    opacity: 0;
    z-index: -2;
    pointer-events: none;
    transition: opacity 0.2s ease-in-out;
    overflow-y: auto;
    padding-top: 15px;
    padding-bottom: 15px;
  }
`;

const MobileMenuStylesOpen = css`
  ${[theme.media.mobileMenuVisible]} {
    opacity: 1;
    z-index: 9999;
    pointer-events: all;
    transition: opacity 0.2s ease-in-out;
  }
`;

const Nav = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  ${[theme.media.mobileMenuVisible]} {
    flex-direction: column;
    text-align: center;
  }
`;

const NavItem = styled.li`
  margin-right: 15px;
  &:last-child {
    margin-right: 20px;
    ${[theme.media.mobileMenuVisible]} {
      margin-right: 0;
    }
  }
  ${[theme.media.mobileMenuVisible]} {
    margin: 0;
    padding-top: 15px;
    padding-bottom: 15px;
  }
`;

const NavLink = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  color: ${theme.colors.gray};
  text-decoration: none;
  ${[theme.media.mobileMenuVisible]} {
    display: inline-flex;
  }
  ${[theme.media.mobileMenuHidden]} {
    &::after {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 0;
      right: 0;
      height: 2px;
      background-color: transparent;
      transition: all 0.2s ease-in-out;
    }

    &:hover::after {
      background-color: ${theme.colors.grayLight};
    }
  }

  &.nav-link--active {
    color: ${theme.colors.blueDark};
    ${[theme.media.mobileMenuHidden]} {
      &::after {
        background-color: ${theme.colors.blueDark};
      }
    }
  }
`;

const NavLinkNavbarScrolled = css`
  &::after {
    bottom: -23px;
    height: 4px;
  }
`;

const Hamburger = styled.div`
  ${[theme.media.mobileMenuHidden]} {
    display: none;
  }
`;

export class Navigation extends React.PureComponent<any, State> {
  state = {
    menuShown: false,
    scrolled: false
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const scrolled = currentScrollPos > 0;

    this.setState({
      scrolled
    });
  };

  render() {
    return (
      <Navbar css={[this.state.scrolled && NavbarScrolled]}>
        <Container>
          <NavbarContent>
            <Logo to="/">
              <img src={logo} alt="Logo" />
            </Logo>
            <div
              css={[
                MobileMenuStyles,
                this.state.menuShown && MobileMenuStylesOpen
              ]}
            >
              <Nav>
                <NavItem>
                  <NavLink
                    css={[this.state.scrolled && NavLinkNavbarScrolled]}
                    to="/"
                    activeClassName="nav-link--active"
                  >
                    <FormattedMessage id="components.navigation.link0" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    css={[this.state.scrolled && NavLinkNavbarScrolled]}
                    to="/why-eledio/"
                    activeClassName="nav-link--active"
                  >
                    <FormattedMessage id="components.navigation.link1" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    css={[this.state.scrolled && NavLinkNavbarScrolled]}
                    to="/hardware/"
                    activeClassName="nav-link--active"
                  >
                    <FormattedMessage id="components.navigation.link2" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    css={[this.state.scrolled && NavLinkNavbarScrolled]}
                    to="/software/"
                    activeClassName="nav-link--active"
                  >
                    <FormattedMessage id="components.navigation.link3" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    css={[this.state.scrolled && NavLinkNavbarScrolled]}
                    to="/powered-by/"
                    activeClassName="nav-link--active"
                  >
                    <FormattedMessage id="components.navigation.link4" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    css={[this.state.scrolled && NavLinkNavbarScrolled]}
                    to="/news/"
                    activeClassName="nav-link--active"
                  >
                    <FormattedMessage id="components.navigation.link5" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    css={[this.state.scrolled && NavLinkNavbarScrolled]}
                    to="/about-us/"
                    activeClassName="nav-link--active"
                  >
                    <FormattedMessage id="components.navigation.link6" />
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
            <Button link="/contact/">
              <FormattedMessage id="components.navigation.link8" />
            </Button>
            <LanguageSwitch />
            <Hamburger onClick={this.toggleMenu}>
              <Menu open={this.state.menuShown} />
            </Hamburger>
          </NavbarContent>
        </Container>
      </Navbar>
    );
  }

  private toggleMenu = () => {
    this.setState({ menuShown: !this.state.menuShown });
  };
}
