import React from "react";
import Container from "../../components/Container";
import {
  arrowLeft,
  circleLarge,
  circleSmall,
  target,
  waveHorizontalLarge
} from "../../components/Images";
import TypingBox from "../../components/TypingBox";
import styled from "../../styling/styled";
import { theme } from "../../styling/theme";

const JumbotronContent = styled.div`
  position: relative;
  display: flex;
  padding-top: 60px;
  min-height: 600px;
  padding-bottom: 20px;
  background-size: 17px 17px, 28px 28px, 160px 17px, 28px 28px, 160px 17px;
  background-image: url(${circleSmall}), url(${target}),
    url(${waveHorizontalLarge}), url(${circleLarge}),
    url(${waveHorizontalLarge});
  background-repeat: no-repeat;
  background-position: left calc(${theme.container.background} - 117px) bottom
      22%,
    left calc(${theme.container.background} - 77px) bottom 38%,
    left calc(${theme.container.background} - 277px) top 15%,
    right calc(${theme.container.background} - 69px) top 19%,
    right calc(${theme.container.background} - 200px) bottom 25%;

  ${[theme.media.mobileMenuVisible]} {
    padding-bottom: 20px;
    min-height: 0;
  }
`;

const ArrowDown = styled.img`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  transform: rotate(-90deg);
  ${[theme.media.mobileMenuVisible]} {
    bottom: 20px;
  }
`;

class Jumbotron extends React.Component {
  render() {
    return (
      <JumbotronContent>
        <Container>
          <TypingBox />
          <a href="#advantages">
            <ArrowDown src={arrowLeft} alt="Arrow down" />
          </a>
        </Container>
      </JumbotronContent>
    );
  }
}

export default Jumbotron;
