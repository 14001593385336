import React from "react";
import styled from "../styling/styled";
import Footer from "./Footer";
import { Navigation } from "./Navigation";

interface Props {
  children: React.ReactNode;
}

const Layout = styled.div`
  padding-top: 80px;
  min-height: 100vh;
`;

export default (props: Props) => {
  return (
    <Layout>
      <Navigation />
      {props.children}
      <Footer />
    </Layout>
  );
};
