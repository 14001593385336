import { FormattedMessage } from "gatsby-plugin-intl";
import React from "react";
import Container from "../../components/Container";
import IconNumbered from "../../components/IconNumbered";
import {
  circleSmall,
  step_1,
  step_2,
  step_3,
  target,
  waveHorizontalLarge,
  waveVerticalSmall
} from "../../components/Images";
import Row from "../../components/Row";
import styled from "../../styling/styled";
import { theme } from "../../styling/theme";

const StepsContent = styled.div`
  padding-top: 72px;
  padding-bottom: 130px;
  text-align: center;
  background-size: 17px 17px, 160px 17px, 17px 47px, 28px 28px;
  background-image: url(${circleSmall}), url(${waveHorizontalLarge}),
    url(${waveVerticalSmall}), url(${target});
  background-repeat: no-repeat;
  background-position: left calc(${theme.container.background} - 85px) top 100px,
    left calc(${theme.container.background} - 250px) bottom 31%,
    right calc(${theme.container.background} - 130px) bottom 20%,
    right calc(${theme.container.background} + 5px) bottom 7%;
  ${[theme.media.mobileMenuVisible]} {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  h2 {
    margin-bottom: 65px;
  }
`;

const Step = styled.div`
  max-width: 270px;
  flex-basis: 270px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`;

const RowSteps = styled(Row)`
  ${[theme.media.mobileMenuVisible]} {
    flex-direction: column;
  }
`;

const Col6 = styled.div`
  flex: 1;
  padding-left: 15px;
  padding-right: 15px;
  ${[theme.media.mobileMenuVisible]} {
    margin-bottom: 30px;
  }
`;

class Steps extends React.Component {
  render() {
    return (
      <StepsContent>
        <Container>
          <h2>
            <FormattedMessage id="partials.homepage.steps.title1" />
          </h2>
          <RowSteps spacing={15}>
            <Col6>
              <Step>
                <IconNumbered src={step_1} alt="Step 1" />
                <h4>
                  <FormattedMessage id="partials.homepage.steps.title2" />
                </h4>
                <p>
                  <FormattedMessage id="partials.homepage.steps.text1" />
                </p>
              </Step>
            </Col6>
            <Col6>
              <Step>
                <IconNumbered src={step_2} alt="Step 2" />
                <h4>
                  <FormattedMessage id="partials.homepage.steps.title3" />
                </h4>
                <p>
                  <FormattedMessage id="partials.homepage.steps.text2" />
                </p>
              </Step>
            </Col6>
            <Col6>
              <Step>
                <IconNumbered src={step_3} alt="Step 3" />
                <h4>
                  <FormattedMessage id="partials.homepage.steps.title4" />
                </h4>
                <p>
                  <FormattedMessage id="partials.homepage.steps.text3" />
                </p>
              </Step>
            </Col6>
          </RowSteps>
        </Container>
      </StepsContent>
    );
  }
}

export default Steps;
