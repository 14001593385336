import React from "react";
import styled from "../styling/styled";

interface IconNumberedProps {
  src: string;
  alt?: string;
}

const IconNumberedContent = styled.img`
  width: 127px;
  height: 127px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 32px;
`;

class IconNumbered extends React.Component<IconNumberedProps> {
  render() {
    const { src, alt } = this.props;

    return <IconNumberedContent src={src} alt={alt} />;
  }
}

export default IconNumbered;
