import { FormattedMessage, Link } from "gatsby-plugin-intl";
import React from "react";
import {
  logoEu,
  logoTheCountryForTheFuture
} from "../components/Images";
import styled from "../styling/styled";
import ContainerSmall from "./ContainerSmall";

const FooterContent = styled.div`
  padding-top: 25px;
  padding-bottom: 25px;
  background: #f0f4fe;
`;

const Text = styled.p`
  margin-bottom: 0;
  text-align: center;
  font-size: 10px;
  color: #a6b1ce;
`;

const LegalLogos = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 0 20px;
  & > * {
    height: 50px;
    margin: 0 10px;
  }
`;

class Footer extends React.Component {
  render() {
    return (
      <FooterContent>
        <LegalLogos>
          <img src={logoEu} alt="" />
          <img src={logoTheCountryForTheFuture} alt="" />
        </LegalLogos>
        <ContainerSmall>
          <Text>
            <FormattedMessage id="components.footer.text1" /> |{" "}
            <Link to="/terms-and-conditions/">
              <FormattedMessage id="components.footer.link1" />
            </Link> | {" "}
            <Link to="/impressum/"> 
              <FormattedMessage id="components.footer.link2" />
            </Link> | {" "}
            <Link to="/privacy-policy/"> 
              <FormattedMessage id="components.footer.link3" />
            </Link>
          </Text>
        </ContainerSmall>
      </FooterContent>
    );
  }
}

export default Footer;
