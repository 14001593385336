import { Link } from "gatsby-plugin-intl";
import React from "react";
import styled from "../styling/styled";
import { media, theme } from "../styling/theme";

export enum ButtonKind {
  Primary = "Primary",
  Large = "Large"
}

interface ButtonProps {
  link: string;
  external?: boolean;
  children: React.ReactNode;
  kind: ButtonKind;
}

const getButtonStyle = (kind: ButtonKind) => {
  switch (kind) {
    case ButtonKind.Primary:
      return {
        padding: "8px 13px"
      };
    case ButtonKind.Large:
      return {
        padding: "8px 52px"
      };
  }
};

const ButtonStyled = styled(Link)<ButtonProps>(({ kind }) => ({
  ...getButtonStyle(kind),
  display: "inline-block",
  color: theme.colors.white,
  backgroundColor: theme.colors.red,
  transition: "all .3s ease-in-out",
  fontWeight: 700,
  fontFamily: theme.font.proxima,
  textDecoration: "none",
  borderRadius: "3px",
  textTransform: "uppercase",
  [":hover"]: {
    backgroundColor: theme.colors.redHover
  },
  [media.maxS]: {
    fontSize: "14px"
  }
}));

class Button extends React.Component<ButtonProps> {
  static defaultProps = {
    kind: ButtonKind.Primary
  };
  render() {
    const { link, external, kind, children } = this.props;

    return (
      <ButtonStyled
        to={link}
        kind={kind}
        target={external ? "_blank" : "_self"}
        rel={external ? "noopener noreferrer" : ""}
      >
        {children}
      </ButtonStyled>
    );
  }
}

export default Button;
