import {
  FormattedHTMLMessage,
  FormattedMessage,
  Link
} from "gatsby-plugin-intl";
import React from "react";
import ContainerLarge from "../../components/ContainerLarge";
import {
  ixfield,
  ixmanager,
  ixsolve, target,
  waveHorizontalSmall,
  waveVerticalLarge,
  what_1,
  what_2,
  what_3,
  what_4
} from "../../components/Images";
import Row from "../../components/Row";
import styled from "../../styling/styled";
import { theme } from "../../styling/theme";

const ExplanationContent = styled.div`
  padding-bottom: 70px;
  background-size: 47px 17px, 28px 28px, 17px 160px;
  background-image: url(${waveHorizontalSmall}), url(${target}),
    url(${waveVerticalLarge});
  background-repeat: no-repeat;
  background-position: right calc(${theme.container.background} - 100px) top
      55px,
    left calc(${theme.container.background} - 20px) top 58%,
    right calc(${theme.container.background} - 120px) bottom 0;

  img.logo {
    height: 30px;
    margin-top: 8px;
    display: block;
  }
`;

const Col6 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  ${[theme.media.maxL]} {
    max-width: 100%;
    flex-basis: 100%;
  }
`;

const ColImage = styled(Col6)`
  ${[theme.media.maxL]} {
    order: -1;
  }
`;

const Text = styled.div`
  max-width: 495px;
  padding: 30px;
  ${[theme.media.maxL]} {
    padding: 60px 30px;
  }

  p:last-child {
    margin-bottom: 0;
  }

  a {
    color: ${theme.colors.red};
    text-decoration: none;
    padding-bottom: 7px;
    border-bottom: 1px solid ${theme.colors.red};
    display: inline-block;
    font-family: ${theme.font.proxima};
    font-weight: 700;
    text-transform: uppercase;
    &:hover {
      border-bottom-color: transparent;
    }
  }
`;

class Explanation extends React.Component {
  render() {
    return (
      <ExplanationContent>
        <ContainerLarge>
          <Row spacing={0}>
            <ColImage>
              <img src={what_1} alt="What is Eledio?" />
            </ColImage>
            <Col6>
              <Text>
                <h2>
                  <FormattedMessage id="partials.homepage.explanation.title1" />
                  
                </h2>
                <h3>
                  <FormattedMessage id="partials.homepage.explanation.title2" />
                </h3>
                <p>
                  <FormattedHTMLMessage id="partials.homepage.explanation.text1a" />
                </p>
                <p>
                  <FormattedHTMLMessage id="partials.homepage.explanation.text1b" />
                </p>
                <Link to="/hardware/">
                  <FormattedMessage id="partials.homepage.explanation.link1" />
                </Link>
              </Text>
            </Col6>
          </Row>
          <Row spacing={0}>
            <Col6>
              <Text>
                <h3>
                  <FormattedMessage id="partials.homepage.explanation.title3" />
                  <img src={ixmanager} alt="iXmanager" className="logo" />
                </h3>
                <p>
                  <FormattedHTMLMessage id="partials.homepage.explanation.text2a" />
                </p>
                <p>
                  <FormattedHTMLMessage id="partials.homepage.explanation.text2b" />
                </p>
                <Link to="/software/">
                  <FormattedMessage id="partials.homepage.explanation.link1" />
                </Link>
              </Text>
            </Col6>
            <ColImage>
              <img
                src={what_2}
                alt="A simple GUI for setting up your device functions"
              />
            </ColImage>
          </Row>
          <Row spacing={0}>
            <ColImage>
              <img
                src={what_3}
                alt="Stunning end-user applications. A powerful cloud back-end."
              />
            </ColImage>
            <Col6>
              <Text>
                <h3>
                  <FormattedMessage id="partials.homepage.explanation.title4" />
                  <img src={ixfield} alt="iXfield" className="logo" />
                </h3>
                <p>
                  <FormattedHTMLMessage id="partials.homepage.explanation.text3a" />
                </p>
                <p>
                  <FormattedHTMLMessage id="partials.homepage.explanation.text3b" />
                </p>
                <p>
                  <FormattedHTMLMessage id="partials.homepage.explanation.text3c" />
                </p>
                <Link to="/software/">
                  <FormattedMessage id="partials.homepage.explanation.link1" />
                </Link>
              </Text>
            </Col6>
          </Row>
          <Row spacing={0}>
            <Col6>
              <Text>
                <h3>
                  <FormattedMessage id="partials.homepage.explanation.title5" />
                  <img src={ixsolve} alt="iXsolve" className="logo" />
                </h3>
                <p>
                  <FormattedHTMLMessage id="partials.homepage.explanation.text4a" />
                </p>
                <p>
                  <FormattedHTMLMessage id="partials.homepage.explanation.text4b" />
                </p>
                <p>
                  <FormattedHTMLMessage id="partials.homepage.explanation.text4c" />
                </p>
                <Link to="/software/">
                  <FormattedMessage id="partials.homepage.explanation.link1" />
                </Link>
              </Text>
            </Col6>
            <ColImage>
              <img
                src={what_4}
                alt="A simple GUI for setting up your device functions"
              />
            </ColImage>
          </Row>
        </ContainerLarge>
      </ExplanationContent>
    );
  }
}

export default Explanation;
