import styled from "../styling/styled";
import { theme } from "../styling/theme";

const ContainerLarge = styled.div`
  width: 100%;
  max-width: ${theme.container.large};
  margin: 0 auto;
`;

export default ContainerLarge;
