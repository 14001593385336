import { FormattedMessage } from "gatsby-plugin-intl";
import React from "react";
import ContainerSmall from "../../components/ContainerSmall";
import Form from "../../components/Form";
import Icon, { IconType } from "../../components/Icons";
import Row from "../../components/Row";
import styled from "../../styling/styled";
import { theme } from "../../styling/theme";

const ContactContent = styled.div`
  padding-top: 100px;
  padding-bottom: 50px;
  background-color: ${theme.colors.blueLight};
  ${[theme.media.mobileMenuVisible]} {
    padding-top: 60px;
    padding-bottom: 30px;
  }

  h2 {
    margin-bottom: 75px;
    text-align: center;
    ${[theme.media.mobileMenuVisible]} {
      margin-bottom: 1.25em;
    }
  }

  p {
    margin-bottom: 2.4em;
  }
`;

const RowContact = styled(Row)`
  ${[theme.media.maxMD]} {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }
`;

const Col6 = styled.div`
  flex: 1;
  padding-left: 30px;
  padding-right: 30px;
  ${[theme.media.maxMD]} {
    padding-left: 0;
    padding-right: 0;
  }
`;

const LinkWithIcon = styled.div`
  display: flex;
  align-items: center;
  font-size: 22px;
  font-family: ${theme.font.proxima};
  font-weight: 400;
  margin-bottom: 2px;
  strong {
    font-weight: 600;
  }
`;

const Link = styled.a`
  color: ${theme.colors.grayDark};
  text-decoration: none;
  margin-left: 25px;
  &:hover {
    text-decoration: underline;
  }
`;

class Contact extends React.Component {
  render() {
    return (
      <ContactContent>
        <ContainerSmall>
          <h2>
            <FormattedMessage id="partials.homepage.contact.title1" />
          </h2>
          <RowContact spacing={30}>
            <Col6>
              <h3>
                <FormattedMessage id="partials.homepage.contact.title2" />
              </h3>
              <p>
                <FormattedMessage id="partials.homepage.contact.text1" />
              </p>
              <LinkWithIcon>
                <Icon width={28} height={28} icon={IconType.Phone} />
                <Link href="tel:+420 724 328 130">
                  +420 <strong>724 328 130</strong>
                </Link>
              </LinkWithIcon>
              <LinkWithIcon>
                <Icon width={28} height={28} icon={IconType.Email} />
                <Link href="mailto:eledio@eledio.com">
                  <strong>eledio@eledio.com</strong>
                </Link>
              </LinkWithIcon>
            </Col6>
            <Col6>
              <Form />
            </Col6>
          </RowContact>
        </ContainerSmall>
      </ContactContent>
    );
  }
}

export default Contact;
